<template>
    <div class="login">
        <b-row class="qoo-row">
            <b-col md="4">

            </b-col>
            <b-col md="4">
                <h1 class="title"><a href="#"><img class="login-logo" src="@/assets/img/qoosquarefulllogo.png"/></a></h1>
                <div style="display: block; text-align: center;">
                  <b-form  v-if="auth.screen === 'recovery2'" @submit.prevent="completeForgotPassword" class="qoo-form">
                    <h3 style="text-align: center; padding-bottom: 5%;">Forgot Password</h3>
                    <b-form-group>
                      <b-input v-model="model.token" minlength="4"  maxlength="4" placeholder="OTP"></b-input>
                    </b-form-group>
                    <b-form-group>
                      <the-mask placeholder="Set Password" type="password" required v-model="model.password" maxlength="6" minlength="6" :mask="['######', '######']" class="form-control" />
                    </b-form-group>
                    <b-form-group>
                      <the-mask placeholder="Retype Password" type="password" required v-model="model.passwordConfirmation" maxlength="6" minlength="6" :mask="['######', '######']" class="form-control" />
                    </b-form-group>
                    <b-form-group>
                      <base-button title="Submit" :loading="auth.authLoading"/>
                    </b-form-group>
                    <p class="align-center">Not a member? <span @click="$router.push('/')" class="span-link">Login to QooSquare</span></p>
                  </b-form>
                  <b-form v-else @submit.prevent="forgotPassword" class="qoo-form">
                      <h3 style="text-align: center; padding-bottom: 5%;">Forgot Password</h3>
                      <b-form-group>
                          <b-input v-model="model.username" placeholder="Username"></b-input>
                      </b-form-group>
                      <b-form-group>
                          <base-button title="Continue" :loading="auth.authLoading"/>
                      </b-form-group>
                      <p class="align-center">Already a member? <span @click="$router.push('/')" class="span-link">Login to QooSquare</span></p>
                  </b-form>
                </div>
            </b-col >
            <b-col md="4">

            </b-col>
        </b-row>
    </div>
</template>

<script>
    import BaseButton from "../../components/button/BaseButton";
    import {mapState} from 'vuex'
    export default {
        name: "Forgot.vue",
        components:{
            BaseButton
        },
        data() {
          return {
            model: {
              mobiledeviceid: '7162734443',
              username: '',
              requestid: '',
              source: 'MOBILE',
              devicedetails: '',
              currentpassword: '',
              uniqueref: '',
              token: '',
              password: '',
              passwordConfirmation: '',
              pin: ''
            },
          }
        },
        methods: {
          getRandomString(length) {
            let randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            let result = '';
            for ( let i = 0; i < length; i++ ) {
              result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
            }
            return result;
          },

          clicked(){
            this.$swal({
              title: 'Custom animation with Animate.css',
              showClass: {
                popup: 'animated slideInLeft faster display-6'
              },
              hideClass: {
                popup: 'animated slideOutRight faster display-6'
              },
              text: "You won't be able to revert this!",
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: 'Yes, delete it!'
            })
          },
          async forgotPassword() {
            console.log('this.getRandomString()>>>>>',this.getRandomString(30))
            this.model.requestid = this.getRandomString(30);
            await this.$store.dispatch("auth/initiateResetPassword", this.model, { root: false })
            this.model.uniqueref = this.auth.response.uniqueref
          },
          async completeForgotPassword() {
            if(this.model.password !== this.model.passwordConfirmation){
              this.$swal('error', 'Password mismatched!')
            }else {
              this.model.email = this.model.userEmail;
              await this.$store.dispatch("auth/completeResetPassword", this.model, {root: false})
              this.model = {
                mobiledeviceid: '7162734443',
                username: '',
                requestid: '1006358033378444334444',
                source: 'MOBILE',
                devicedetails: '',
                currentpassword: '',
                uniqueref: '',
                token: '',
                password: '',
                passwordConfirmation: '',
                pin: ''
              }
            }
          },
        },
        computed:{
          ...mapState([
            'auth'
          ]),
          minLength:function(){
            return this.model.password.length >= 8?'green':'red'
          },
          maxLength:function(){
            return this.model.password.length <= 13?'green':'red'
          },
          hasLettersNumbers:function(){
            return ( /[a-zA-Z]/g.test(this.model.password) && /[0-9]/g.test(this.model.password))?'green':'red'
          },
          hasSpecialXters:function(){
            return /[^a-zA-Z0-9]/g.test(this.model.password)?'green':'red'
          },
          hasLowerCase:function(){
            return /[a-z]/g.test(this.model.password)?'green':'red'
          },
          hasUpperCase:function(){
            return /[A-Z]/g.test(this.model.password)?'green':'red'
          },
          hasSpace:function(){
            return !(/\s/g.test(this.model.password))?'green':'red'
          },

        },

        watch: {
          'auth': function() {
            console.log('Hello World!')
          }
        },
        mounted() {
          this.$store.commit("auth/updateScreen",'recovery1')
          // this.$store.auth.watch(this.$store.getters.getN, n => {
          //     console.log('watched: ', n)
          // })
        },
    }
</script>

<style scoped>

@import "../../assets/style/css/style.css";

</style>