<template>
    <div class="body">
        <div class="preloader">
            <div class="preloader__bar"></div>
            <div class="preloader__bar"></div>
            <div class="preloader__bar"></div>
            <div class="preloader__bar"></div>
            <div class="preloader__bar"></div>
            <div class="preloader__ball"></div>
        </div>
        <br><br><br><br><br>
<!--        <img src="https://assets-base.s3.amazonaws.com/img/firstbank/logo.png" class="img-fluid"  height="40px" width="100px" alt="FBNBank Logo">-->
    </div>
</template>

<script>
    // import store from "../../store/store";
    import {mapState} from "vuex";
    export default {
        name: "Preloader",
        data(){
            return{
                finger:''
            }
        },
        beforeCreate() {
            this.$fingerprint.get((components) => {
                this.finger = this.$fingerprint.x64hash128(components.map((pair) => { return pair.value }).join(), 31)
                console.log(this.finger)
                this.$store.commit('auth/updateDeviceId', this.finger)
            });
        },
        mounted() {
            this.$store.dispatch("auth/validate", {}, {root: true}).then(validUser => {
                console.log('validUser>>>>>>>>',validUser)
                if (validUser){
                    this.$store.dispatch('auth/readCompany', {
                      userId: this.auth.userInfo.id,
                      requestType: 'company'
                    }).then(()=>{
                      if (this.auth.userInfo.companies.data.length < 1)
                        //console.log('>>><<<<')
                        this.initDashboard()
                        // this.$router.push('/new-company').catch(err => {
                        //   console.log(err)
                        // });
                      else {
                        if(localStorage.orgId == null && this.auth.userInfo.companies.data[0] != null){
                          localStorage.orgId = this.auth.userInfo.companies.data[0].orgId
                        }
                        // eslint-disable-next-line no-unused-vars
                        this.initDashboard()
                      }
                    })
                }
                else
                if (this.$route.fullPath !== '/') {
                  if (localStorage.source == null)
                    this.$router.push('/')
                  else
                    this.$router.push('/login/'+localStorage.source)
                }
            })
        },
        methods:{
            async initDashboard() {
                // await this.$store.dispatch("loan/updateLoans", {}, {root: true}).then()
                // this.$store.dispatch("employee/updateEmployees", {
                //     readAll:'NO',
                //     employerId: localStorage.orgId
                // }, {root: false}).then()
                this.$router.push('/dashboard').catch(err => {
                    console.log(err)
                });
            }
        },
        computed: {
            ...mapState([
                'loan',
                'proprietor',
                'auth'
            ]),
        },
    }
</script>
